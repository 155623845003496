import { StaticImageData } from "next/image";

import { Image } from "@/components/displays";

export interface LineupSectionItemProps {
  category: string;
  title: string;
  imageSrc: StaticImageData;
  description: string;
  registeredMark?: boolean;
}

export function LineupSectionItem({
  category,
  title,
  imageSrc,
  description,
  registeredMark,
}: LineupSectionItemProps): React.ReactNode {
  return (
    <>
      <Image src={imageSrc} alt={title} width={224} sizes="224px" />
      <p className="text__s text__bold text__white text__center mg__top__s">
        BASE {category}
        {registeredMark && (
          <sup className="sup">
            <i className="far fa-registered fa-fw"></i>
          </sup>
        )}
      </p>
      <p className="text__m text__bold text__center mg__bottom__s">{title}</p>
      <p className="text__m">{description}</p>
    </>
  );
}
