import { StaticImageData } from "next/image";

import product_milk from "$/images/lp/product_milk.png";
import product_sliceorange from "$/images/lp/product_sliceorange.png";
import milk_ura from "$/images/product/milk/pkg_bb_milk_ura.png";
import sliceorange_ura from "$/images/product/slice_orange/thumb_bb_slice_orange_ura.png";

interface Product {
  id: string;
  src: string | StaticImageData;
  alt: string;
  aspectRatio: string;
  detailSrc: string | StaticImageData;
  detailAspectRatio: string;
}

export const lpProducts: Product[] = [
  {
    id: "chocolate",
    src: "https://asset.basefood.co.jp/lp/common/product_chocolate_v1_12.png",
    alt: "Chocolate",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_chocolate_v1_12_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "maple",
    src: "https://asset.basefood.co.jp/lp/common/product_maple_v1_6.png",
    alt: "Maple",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_maple_v1_6_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "koshian",
    src: "https://asset.basefood.co.jp/lp/common/product_koshian_v1_1.png",
    alt: "Koshian",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_koshian_v1_1_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "milk",
    src: product_milk,
    alt: "Milk",
    aspectRatio: "1005 / 376",
    detailSrc: milk_ura,
    detailAspectRatio: "125 / 103",
  },
  {
    id: "sliceRaisin",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_raisin_v1_3.png",
    alt: "Raisin Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_raisin_v1_3_ura.png",
    detailAspectRatio: "980 / 995",
  },
  {
    id: "sliceOrange",
    src: product_sliceorange.src,
    alt: "Slice Orange",
    aspectRatio: "1005 / 376",
    detailSrc: sliceorange_ura.src,
    detailAspectRatio: "1 / 1",
  },
  {
    id: "slicePlain",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_plain_v1_5.png",
    alt: "Plain Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_plain_v1_5_ura.png",
    detailAspectRatio: "980 / 993",
  },
  {
    id: "plain",
    src: "https://asset.basefood.co.jp/lp/common/product_plain_v3_11.png",
    alt: "Plain",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_plain_v3_11_ura.png",
    detailAspectRatio: "980 / 807",
  },
  {
    id: "curry",
    src: "https://asset.basefood.co.jp/lp/common/product_curry_v1_6.png",
    alt: "Curry",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_curry_v1_6_ura.png",
    detailAspectRatio: "980 / 807",
  },
];
