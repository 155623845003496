import { ImageProps } from "next/image";

import sauceyakisoba_closed from "$/images/lp/yakisoba/sauceyakisoba-closed.jpg";
import sauceyakisoba_content from "$/images/lp/yakisoba/sauceyakisoba-content.jpg";
import sauceyakisoba_open from "$/images/lp/yakisoba/sauceyakisoba-open.jpg";
import sauceyakisoba_title from "$/images/lp/yakisoba/sauceyakisoba-title.jpg";
import sioyakisoba_closed from "$/images/lp/yakisoba/sioyakisoba-closed.jpg";
import sioyakisoba_content from "$/images/lp/yakisoba/sioyakisoba-content.jpg";
import sioyakisoba_open from "$/images/lp/yakisoba/sioyakisoba-open.jpg";
import sioyakisoba_title from "$/images/lp/yakisoba/sioyakisoba-title.jpg";
import umakaramazesoba_closed from "$/images/lp/yakisoba/umakaramazesoba-closed.jpg";
import umakaramazesoba_content from "$/images/lp/yakisoba/umakaramazesoba-content.jpg";
import umakaramazesoba_open from "$/images/lp/yakisoba/umakaramazesoba-open.jpg";
import umakaramazesoba_title from "$/images/lp/yakisoba/umakaramazesoba-title.jpg";

interface Product {
  id: string;
  alt: string;
  titleSrc: ImageProps["src"];
  closedSrc: ImageProps["src"];
  opendSrc: ImageProps["src"];
  contentSrc: ImageProps["src"];
}

export const lpProducts: Product[] = [
  {
    id: "sauceyakisoba",
    alt: "ソース焼きそば 香ばしくまろやかなソースの味わい",
    titleSrc: sauceyakisoba_title,
    closedSrc: sauceyakisoba_closed,
    opendSrc: sauceyakisoba_open,
    contentSrc: sauceyakisoba_content,
  },
  {
    id: "umakaramazesoba",
    alt: "旨辛まぜそば 4種のスパイスが効いた汁なし辛麺",
    titleSrc: umakaramazesoba_title,
    closedSrc: umakaramazesoba_closed,
    opendSrc: umakaramazesoba_open,
    contentSrc: umakaramazesoba_content,
  },
  {
    id: "sioyakisoba",
    alt: "塩焼きそば シンプルな塩の旨味が広がる贅沢な味わい",
    titleSrc: sioyakisoba_title,
    closedSrc: sioyakisoba_closed,
    opendSrc: sioyakisoba_open,
    contentSrc: sioyakisoba_content,
  },
];
