import { productNameSkuMap } from "@/configs/skuDB";
import { ProductOptionModel } from "@/models/product/type";

export const YAKISOBA_START_SET: ProductOptionModel[] = [
  {
    name: "8個セット（塩焼きそば4個、ソース焼きそば4個）",
    products: [
      { sku: productNameSkuMap.baseyakisoba_shioyakisoba, quantity: 4 },
      { sku: productNameSkuMap.baseyakisoba_sauceyakisoba, quantity: 4 },
    ],
    sub_title: "8個セット（塩焼きそば4個、ソース焼きそば4個）",
  },
  {
    name: "8個セット（旨辛まぜそば4個、ソース焼きそば4個）",
    products: [
      { sku: productNameSkuMap.baseyakisoba_sauceyakisoba, quantity: 4 },
      { sku: productNameSkuMap.baseyakisoba_umakaramazesoba, quantity: 4 },
    ],
    sub_title: "8個セット（旨辛まぜそば4個、ソース焼きそば4個）",
  },
  {
    name: "12個セット（塩焼きそば4個、BASE BREAD 8袋）",
    products: [
      { sku: productNameSkuMap.baseyakisoba_shioyakisoba, quantity: 4 },
      { sku: productNameSkuMap.basebread_chocolate, quantity: 2 },
      { sku: productNameSkuMap.basebread_maple, quantity: 2 },
      { sku: productNameSkuMap.basebread_koshian, quantity: 2 },
      { sku: productNameSkuMap.basebread_milk, quantity: 2 },
    ],
    sub_title: "12個セット（塩焼きそば4個、BASE BREAD 8袋）",
  },
];
