"use client";

import { StaticImageData } from "next/image";

import { ImageLegacy, Scroll } from "@/components/displays";
import { useLPStore } from "@/components/domains/lp/store";

import styles from "./AnchorButton.module.scss";

export interface AnchorButtonProps {
  productSetIndex?: number;
  imageSrc: StaticImageData;
}

export function AnchorButton({ productSetIndex, imageSrc }: AnchorButtonProps) {
  const { setSelectIndex } = useLPStore();

  return (
    <div className={styles.wrapper}>
      <Scroll
        to="lp-form"
        onClick={() => productSetIndex !== undefined && setSelectIndex(productSetIndex)}
      >
        <ImageLegacy
          src={imageSrc}
          alt="今すぐ試してみる"
          aspectRatio="25 / 4"
          className={styles.image}
        />
      </Scroll>
    </div>
  );
}
