import ImgThumbBBChocolateOmote from "$/images/pkg/thumb_bb_chocolate_omote.png";
import ImgThumbBBCurryOmote from "$/images/pkg/thumb_bb_curry_omote.png";
import ImgThumbBBKoshianOmote from "$/images/pkg/thumb_bb_koshian_omote.png";
import ImgThumbBBMapleOmote from "$/images/pkg/thumb_bb_maple_omote.png";
import ImgThumbBBMilkOmote from "$/images/pkg/thumb_bb_milk_omote.png";
import ImgThubmBBPlainOmote from "$/images/pkg/thumb_bb_plain_omote.png";
import ImgThumbBBOrangeOmote from "$/images/pkg/thumb_bb_slice_orange_omote.png";
import ImgThumbBBSlicePlainOmote from "$/images/pkg/thumb_bb_slice_plain_omote.png";
import ImgThumbBBSliceRaisinOmote from "$/images/pkg/thumb_bb_slice_raisin_omote.png";

import { type LineupSectionItemProps } from "./LineupSectionItem";

export const items: LineupSectionItemProps[] = [
  {
    category: "BREAD",
    title: "チョコレート",
    imageSrc: ImgThumbBBChocolateOmote,
    description: "しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。",
  },
  {
    category: "BREAD",
    title: "メープル",
    imageSrc: ImgThumbBBMapleOmote,
    description: "まろやかな甘みにほっとする、スティックタイプのメープルパン。",
  },
  {
    category: "BREAD",
    title: "こしあん",
    imageSrc: ImgThumbBBKoshianOmote,
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    category: "BREAD",
    title: "ミルク",
    imageSrc: ImgThumbBBMilkOmote,
    description: "北海道産牛乳を使用した、ほんのり甘いミルク風味のもっちりスティックパン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・レーズン",
    imageSrc: ImgThumbBBSliceRaisinOmote,
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },

  {
    category: "BREAD",
    title: "ミニ食パン・オレンジ",
    imageSrc: ImgThumbBBOrangeOmote,
    description: "オレンジの爽やかな香りと、ほろ苦くやさしい甘み広がるミニ食パン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・プレーン",
    imageSrc: ImgThumbBBSlicePlainOmote,
    description: "やさしい甘みでアレンジ楽しい全粒粉ミニ食パン。",
  },
  {
    category: "BREAD",
    title: "プレーン",
    imageSrc: ImgThubmBBPlainOmote,
    description: "ハンバーガーなどアレンジ自在、やさしい甘みの全粒粉の丸パン。",
  },
  {
    category: "BREAD",
    title: "カレー",
    imageSrc: ImgThumbBBCurryOmote,
    description: "揚げずに焼いて仕上げた、やわもち食感の濃厚カレーパン。",
  },
];
